import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { LineChart, BarChart, PieChart } from '@mui/x-charts';

const initialBritishFunds = [
  { id: 0, label: 'AB Fidelity Master Trust', value: 402636.86 },
  { id: 1, label: 'SB Fidelity Master Trust', value: 123376.76 },
  { id: 2, label: 'AB Nutmeg Pension Fund', value: 83432 },
  { id: 3, label: 'SB Nutmeg Pension Fund', value: 6851 }
];

const initialAustralianFunds = [
  { id: 4, label: 'AB Australian Super', value: 18514.22 },
  { id: 5, label: 'SB Australian Super', value: 7728.23 },
  { id: 6, label: 'CBA Black Rock ETF', value: 2034.49 },
];

const historicalData = [
  { date: '2024-01-01', price: 100 },
  { date: '2024-02-01', price: 120 },
  { date: '2024-03-01', price: 110 },
  { date: '2024-04-01', price: 130 },
  { date: '2024-05-01', price: 150 },
];

// MAIN COMPONENT
const PensionDashboard = ({ themeMode }) => {
  const theme = useTheme();
  const [britishFunds, setBritishFunds] = useState([]);
  const [australianFunds, setAustralianFunds] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(1.98);

  useEffect(() => {
    const savedBritishFunds = JSON.parse(localStorage.getItem('britishFunds')) || initialBritishFunds;
    const savedAustralianFunds = JSON.parse(localStorage.getItem('australianFunds')) || initialAustralianFunds;
    const savedExchangeRate = JSON.parse(localStorage.getItem('exchangeRate')) || 1.98;

    setBritishFunds(savedBritishFunds);
    setAustralianFunds(savedAustralianFunds);
    setExchangeRate(savedExchangeRate);
  }, []);

  // Recalculate the total worldwide in AUD
  const totalWorldwideData = [
    ...britishFunds.map(fund => ({ label: fund.label, value: fund.value * exchangeRate })),
    ...australianFunds
  ];

  const totalWorldwide = totalWorldwideData.reduce((acc, fund) => acc + fund.value, 0).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
  const totalGBP = britishFunds.reduce((acc, fund) => acc + fund.value, 0).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
  const totalAUD = australianFunds.reduce((acc, fund) => acc + fund.value, 0).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });

  const uniqueColors = theme.palette.mode === 'dark'
    ? ['#90caf9', '#80cbc4', '#ffcc80', '#ef9a9a', '#ce93d8', '#9fa8da', '#a5d6a7']
    : ['#1976d2', '#26a69a', '#ffca28', '#ef5350', '#ab47bc', '#5c6bc0', '#66bb6a'];

  return (
    <Box
      sx={{
        p: 3, gap: 2,
        backgroundColor: theme.palette.mode === 'light' ? '#bbdefb' : theme.palette.background.paper,
        overflowX: 'auto',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          textAlign: 'center',
          pb: 2
        }}
      >
        Pension Dashboard
      </Typography>

      <Box sx={{
        maxWidth: 1000,
        mx: 'auto',
        overflowY: 'auto', // Ensure individual container scrollable
        WebkitOverflowScrolling: 'touch'
      }}>

        {/* Total Worldwide in AUD Pie Chart */}
        <Paper elevation={10} sx={{ width: '100%', p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>Total Worldwide (AUD): {totalWorldwide}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 650,
              marginTop: -28,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <PieChart
              series={[{
                data: totalWorldwideData,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                arcLabel: (data) => {
                  const valueInThousands = (data.value / 1000).toFixed(0);
                  return `$${valueInThousands}k`;
                },
                arcLabelMinAngle: 25,
                labelStyle: { fontSize: 8 },
                legendLabel: (data) => data.label
              }]}
              width={300}
              height={650}
              colors={uniqueColors}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'bottom', horizontal: 'center' },
                  padding: 0
                }
              }}
            />
          </Box>
        </Paper>

        {/* Total GBP Pie Chart */}
        <Paper elevation={10} sx={{ width: '100%', p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>British Funds: {totalGBP}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 500,
              marginTop: -17,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <PieChart
              series={[{
                data: britishFunds,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                arcLabel: (data) => {
                  const valueInThousands = (data.value / 1000).toFixed(0);
                  return `£${valueInThousands}k`;
                },
                arcLabelMinAngle: 25,
                labelStyle: { fontSize: 8 },
                legendLabel: (data) => data.label
              }]}
              width={300}
              height={500}
              colors={uniqueColors}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'bottom', horizontal: 'center' },
                  padding: 10
                }
              }}
            />
          </Box>
        </Paper>

        {/* Total AUD Pie Chart */}
        <Paper elevation={10} sx={{ width: '100%', p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>Australian Funds: {totalAUD}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 420,
              marginTop: -11,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <PieChart
              series={[{
                data: australianFunds,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                arcLabel: (data) => {
                  const valueInThousands = (data.value / 1000).toFixed(0);
                  return `$${valueInThousands}k`;
                },
                arcLabelMinAngle: 25,
                labelStyle: { fontSize: 8 },
                legendLabel: (data) => data.label
              }]}
              width={300}
              height={420}
              colors={uniqueColors}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'bottom', horizontal: 'center' },
                  padding: 10
                }
              }}
            />
          </Box>
        </Paper>

        {/* Line Chart: Historical Data */}
        <Paper elevation={10} sx={{ width: '100%', p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>Change Over Time</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 380,
              marginTop: -5,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <LineChart
              xAxis={[
                {
                  data: historicalData.map((item) => new Date(item.date)),
                  label: 'Date',
                  scaleType: 'time',
                },
              ]}
              series={[
                {
                  data: historicalData.map((item) => item.price),
                  label: 'Fund Price',
                },
              ]}
              width={300}
              height={300}
              colors={theme.palette.mode === 'dark' ? ['#90caf9'] : ['#1976d2']}
            />
          </Box>
        </Paper>

        {/* Bar Chart: Revenue Comparison */}
        <Paper elevation={10} sx={{ width: '100%', p: 2 }}>
          <Typography variant="h6" gutterBottom>UK Fund Values</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 380,
              marginTop: -5,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <BarChart
              xAxis={[
                {
                  data: britishFunds.map((item) => item.label),
                  label: 'Fund',
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: britishFunds.map((item) => item.value),
                  label: 'Value',
                },
              ]}
              width={300}
              height={300}
              colors={theme.palette.mode === 'dark' ? ['#ffb74d'] : ['#f57c00']}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PensionDashboard;