import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const shares = [
    { id: 1, name: 'Share A', value: 10000 },
    { id: 2, name: 'Share B', value: 15000 },
    { id: 3, name: 'Share C', value: 5000 },
];

const SharesDashboard = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    pb: 2
                }}
            >
                Shares Dashboard
            </Typography>
            {shares.map((share) => (
                <Paper key={share.id} elevation={3} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6">{share.name}</Typography>
                    <Typography>Value: ${share.value.toLocaleString()}</Typography>
                </Paper>
            ))}
        </Box>
    );
};

export default SharesDashboard;