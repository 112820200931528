import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const NetWorthDashboard = () => {
  return (
    <Box sx={{ p: 3 }}>
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    pb: 2
                }}
            >
                Net Worth
            </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography sx={{ py: 5, textAlign: 'center' }}>Content for calculating and displaying net worth goes here</Typography>
      </Paper>
    </Box>
  );
};

export default NetWorthDashboard;