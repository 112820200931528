import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';

const UpdateMortgage = () => {
  const [mortgages, setMortgages] = useState([
    { id: 1, name: 'London', balance: 250000 },
    { id: 2, name: 'Redcliffe', balance: 150000 },
    { id: 3, name: 'Parkwood', balance: 50000 },
  ]);

  const handleUpdate = (id, value) => {
    setMortgages(mortgages.map((mortgage) => 
      mortgage.id === id ? { ...mortgage, balance: value } : mortgage
    ));
  };

  const handleSave = () => {
    localStorage.setItem('mortgages', JSON.stringify(mortgages));
    alert('Mortgages updated successfully!');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          textAlign: 'center',
          pb: 2
        }}
      >
        Update Mortgages
      </Typography>
      {mortgages.map((mortgage) => (
        <Paper key={mortgage.id} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ pb: 1 }}>{mortgage.name}</Typography>
          <TextField
            label="Balance"
            type="number"
            fullWidth
            value={mortgage.balance}
            onChange={(e) => handleUpdate(mortgage.id, parseFloat(e.target.value))}
          />
        </Paper>
      ))}
      <Button variant="contained" color="primary" onClick={handleSave}>Save Changes</Button>
    </Box>
  );
};

export default UpdateMortgage;
