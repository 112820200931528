import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const mortgages = [
    { id: 1, name: 'London', balance: 313000 },
    { id: 2, name: 'Redcliffe', balance: 322000 },
    { id: 3, name: 'Parkwood', balance: 265000 },
];

const MortgageDashboard = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    pb: 2
                }}
            >
                Mortgage Dashboard
            </Typography>
            {mortgages.map((mortgage) => (
                <Paper key={mortgage.id} elevation={3} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6">{mortgage.name}</Typography>
                    <Typography>Balance: ${mortgage.balance.toLocaleString()}</Typography>
                </Paper>
            ))}
        </Box>
    );
};

export default MortgageDashboard;