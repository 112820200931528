import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, IconButton, Box, Tooltip, useTheme } from '@mui/material';
import { Visibility, Edit } from '@mui/icons-material';

const DashboardCard = ({ title, icon: Icon, viewLink, updateLink, showUpdate = true }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                width: { xs: '47.5%', md: '200px' },
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '16px'
            }}
            elevation={5}
        >
            <CardContent>
                <IconButton>
                    <Icon sx={{ fontSize: 80 }} />
                </IconButton>
                <Typography variant="h5">{title}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 1 }}>
                    <Tooltip title="View">
                        <IconButton
                            component={Link}
                            to={viewLink}
                            sx={{
                                bgcolor: 'primary.dark',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main
                                }
                            }}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {showUpdate && (
                        <Tooltip title="Edit">
                            <IconButton
                                component={Link}
                                to={updateLink}
                                sx={{
                                    bgcolor: 'warning.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: theme.palette.warning.light
                                    }
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default DashboardCard;