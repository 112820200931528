import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
} from '@mui/material';

import { LinkedIn } from '@mui/icons-material';

// Replace with the actual path to the logo image
// import logo from './public/ainz.jpg';

const Home = () => {
  return (
    <Box sx={{ minHeight: '100vh', py: 5 }}>
      <Container maxWidth="lg">
        {/* Logo Section */}
        <Box display="flex" justifyContent="center" mb={4}>
          <img
            src={undefined}
            alt="Bowker Software Solutions Logo"
            style={{ maxWidth: '300px', width: '100%', height: 'auto' }}
          />
        </Box>
        {/* Company Overview */}
        <Typography
          variant="h3"
          align="center"
          sx={{ fontWeight: 'bold' }}
        >
          Bowker Software Solutions
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{ color: 'text.secondary', mb: 4 }}
        >
          Delivering innovative software solutions tailored to your business
          needs
        </Typography>
        {/* Director Information */}
        <Box display="flex" justifyContent="center" mb={5}>
          <Card sx={{ maxWidth: 400, width: '100%', textAlign: 'center' }}>
            <CardContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Ainsley Bowker</strong> is the director of Bowker
                Software Solutions, bringing extensive experience in software
                development and project management
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href="https://www.linkedin.com/in/ainsleybowker/"
                target="_blank"
                startIcon={<LinkedIn />}
              >
                View LinkedIn Profile
              </Button>
            </CardContent>
          </Card>
        </Box>
        {/* Portfolio Section */}
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Portfolio Projects
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
            Explore our portfolio to see the diverse range of projects we have
            successfully delivered
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            href="#"
            target="_blank"
          >
            View Portfolio
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;