import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import Home from './Home';
import Navbar from './Navbar';

import NetWorthDashboard from './Dashboards/NetWorthDashboard';

import PensionDashboard from './Dashboards/PensionDashboard';
import UpdatePension from './Updates/UpdatePension';

import MortgageDashboard from './Dashboards/MortgageDashboard';
import UpdateMortgage from './Updates/UpdateMortgage';

import SharesDashboard from './Dashboards/SharesDashboard';
import UpdateShares from './Updates/UpdateShares';
import FinanceDashboard from './FinanceDashboard';

function App() {
  const [themeMode, setThemeMode] = useState('light');

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    console.log(`Theme toggled to ${themeMode === 'light' ? 'dark' : 'light'}`);
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          background: {
            default: themeMode === 'light' ? '#bbdefb' : '#121212',
          },
        },
      }),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar toggleTheme={toggleTheme} themeMode={themeMode} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/finances" element={<FinanceDashboard />} />
          <Route path="/pensionDashboard" element={<PensionDashboard />} />
          <Route path="/updatePension" element={<UpdatePension />} />
          <Route path="/netWorth" element={<NetWorthDashboard />} />
          <Route path="/mortgageDashboard" element={<MortgageDashboard />} />
          <Route path="/updateMortgage" element={<UpdateMortgage />} />
          <Route path="/sharesDashboard" element={<SharesDashboard />} />
          <Route path="/updateShares" element={<UpdateShares />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;