import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, useTheme } from '@mui/material';

const initialBritishFunds = [
    { id: 0, label: 'AB Fidelity Master Trust', value: 402636.86 },
    { id: 1, label: 'SB Fidelity Master Trust', value: 123376.76 },
    { id: 2, label: 'AB Nutmeg Pension Fund', value: 83432 },
    { id: 3, label: 'SB Nutmeg Pension Fund', value: 6851 }
];

const initialAustralianFunds = [
    { id: 4, label: 'AB Australian Super', value: 18514.22 },
    { id: 5, label: 'SB Australian Super', value: 7728.23 },
    { id: 6, label: 'CBA Black Rock ETF', value: 2034.49 },
];

const initialExchangeRate = 1.98;

const UpdatePension = ({ themeMode }) => {
    const theme = useTheme();
    const [britishFunds, setBritishFunds] = useState([]);
    const [australianFunds, setAustralianFunds] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(initialExchangeRate);

    useEffect(() => {
        const savedBritishFunds = JSON.parse(localStorage.getItem('britishFunds')) || initialBritishFunds;
        const savedAustralianFunds = JSON.parse(localStorage.getItem('australianFunds')) || initialAustralianFunds;
        const savedExchangeRate = JSON.parse(localStorage.getItem('exchangeRate')) || initialExchangeRate;

        setBritishFunds(savedBritishFunds);
        setAustralianFunds(savedAustralianFunds);
        setExchangeRate(savedExchangeRate);
    }, []);

    const handleChange = (id, type, value) => {
        const updatedFunds = type === 'british'
            ? britishFunds.map(fund => (fund.id === id ? { ...fund, value: parseFloat(value) } : fund))
            : australianFunds.map(fund => (fund.id === id ? { ...fund, value: parseFloat(value) } : fund));

        type === 'british' ? setBritishFunds(updatedFunds) : setAustralianFunds(updatedFunds);
    };

    const handleSave = () => {
        localStorage.setItem('britishFunds', JSON.stringify(britishFunds));
        localStorage.setItem('australianFunds', JSON.stringify(australianFunds));
        localStorage.setItem('exchangeRate', JSON.stringify(exchangeRate));
        alert('Values saved successfully!');
    };

    return (
        <Box
            sx={{
                p: 2, gap: 2,
                backgroundColor: theme.palette.mode === 'light' ? '#bbdefb' : theme.palette.background.paper,
                overflowX: 'auto',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                mx: 'auto'
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignContent: 'center',
                maxWidth: 600,
                mx: 'auto'
            }}>
                <Paper elevation={5} sx={{ p: 2, mb: 2, maxWidth: 600 }}>
                    <Typography variant="h6" gutterBottom>Update UK Funds</Typography>
                    {britishFunds.map((fund) => (
                        <Box key={fund.id} sx={{ mb: 2 }}>
                            <TextField
                                label={fund.label}
                                type="number"
                                fullWidth
                                value={fund.value}
                                onChange={(e) => handleChange(fund.id, 'british', e.target.value)}
                            />
                        </Box>
                    ))}
                </Paper>
                <Paper elevation={5} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>Update Australian Funds</Typography>
                    {australianFunds.map((fund) => (
                        <Box key={fund.id} sx={{ mb: 2 }}>
                            <TextField
                                label={fund.label}
                                type="number"
                                fullWidth
                                value={fund.value}
                                onChange={(e) => handleChange(fund.id, 'australian', e.target.value)}
                            />
                        </Box>
                    ))}
                </Paper>
                <Paper elevation={5} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>Exchange Rate (GBP to AUD)</Typography>
                    <TextField
                        label="Exchange Rate"
                        type="number"
                        fullWidth
                        value={exchangeRate}
                        onChange={(e) => setExchangeRate(parseFloat(e.target.value))}
                    />
                </Paper>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save Values
                </Button>
            </Box>
        </Box>
    );
};

export default UpdatePension;