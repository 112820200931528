import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';

const UpdateShares = () => {
  const [shares, setShares] = useState([
    { id: 1, name: 'Share A', value: 10000 },
    { id: 2, name: 'Share B', value: 15000 },
    { id: 3, name: 'Share C', value: 5000 },
  ]);

  const handleUpdate = (id, value) => {
    setShares(shares.map((share) => 
      share.id === id ? { ...share, value: value } : share
    ));
  };

  const handleSave = () => {
    localStorage.setItem('shares', JSON.stringify(shares));
    alert('Shares updated successfully!');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          textAlign: 'center',
          pb: 2
        }}
      >
        Update Shares
      </Typography>
      {shares.map((share) => (
        <Paper key={share.id} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ pb: 1 }}>{share.name}</Typography>
          <TextField
            label="Value"
            type="number"
            fullWidth
            value={share.value}
            onChange={(e) => handleUpdate(share.id, parseFloat(e.target.value))}
          />
        </Paper>
      ))}
      <Button variant="contained" color="primary" onClick={handleSave}>Save Changes</Button>
    </Box>
  );
};

export default UpdateShares;