import React from 'react';
import { Box, useTheme } from '@mui/material';
import { AccountBalance, HolidayVillage, Savings, TrendingUp } from '@mui/icons-material';
import DashboardCard from './DashboardCard';

const FinanceDashboard = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#bbdefb' : theme.palette.background.paper,
      }}
    >
      {/* Container for the Cards with responsive layout */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: 1000,
          width: '100%',
          pt: 5,
        }}
      >
        <DashboardCard
          title="Net Worth"
          icon={AccountBalance}
          viewLink="/netWorth"
          updateLink=""
          showUpdate={false}
        />
        <DashboardCard
          title="Property"
          icon={HolidayVillage}
          viewLink="/mortgageDashboard"
          updateLink="/updateMortgage"
        />
        <DashboardCard
          title="Pension"
          icon={Savings}
          viewLink="/pensionDashboard"
          updateLink="/updatePension"
        />
        <DashboardCard
          title="Shares"
          icon={TrendingUp}
          viewLink="/sharesDashboard"
          updateLink="/updateShares"
        />
      </Box>
    </Box>
  );
};

export default FinanceDashboard;